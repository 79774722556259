import React, { useEffect, useState } from "react";

import Container from "@mui/material/Container";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { serviceGetBannerHomeBottom } from "../../../services/content";

import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

const HotPromo = styled.div`
  padding: 24px 0 12px 0;
  font-weight: 600;
  color: #101828;
  line-height: 24px;
  font-size: 16px;
`;
export default function HotPromoArea() {
  const { t } = useTranslation();
  const [listBanners, setListBanners] = useState([]);

  useEffect(async () => {
    const data = await serviceGetBannerHomeBottom();
    setListBanners(data || []);
  }, []);

  return (
    <>
      {listBanners.length ? (
        <>
          <Container>
            <HotPromo>{t("hot_promo")}</HotPromo>
          </Container>
          <Carousel
            showIndicators={false}
            showThumbs={false}
            showStatus={false}
          >
            {listBanners.map((item) => (
              <img key={item.small_image} src={item.small_image} width="100%" />
            ))}
          </Carousel>
        </>
      ) : null}
    </>
  );
}
