import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import styled from "styled-components";
import Container from "@mui/material/Container";

import BoxChangeDisposition from "components/Page/Home/BoxChangeDisposition";
import Disposition from "components/Page/Home/Disposition";
import TopBanner from "components/Page/Home/TopBanner";
import HotPromoArea from "components/Page/Home/HotPromoArea";
import Image from "next/image";

import HomeInit from "hoc/HomeInit";

const Greeting = styled.div`
  font-weight: 600;
  color: #101828;
  line-height: 24px;
  font-size: 16px;
`;

const SubGreeting = styled.div`
  font-weight: 400;
  color: #667085;
  line-height: 18px;
  font-size: 12px;
`;

const ContainerBg = styled.div`
  background-color: #f9fafb;
  padding: 24px 0;
`;

const PaymentImage = styled.div`
  margin-top: 20px;
  text-align: center;
`;

const Home = ()=> {
  const { t } = useTranslation();
  const customerName = useSelector(
    ({ customerReducer }) => customerReducer.name,
  );
  const store = useSelector(({ storeReducer }) => storeReducer.store);
  const cart = useSelector(({ orderReducer }) => orderReducer.cart);

  return (
    <React.Fragment>
      <TopBanner />
      <ContainerBg>
        <Container>
          <Greeting>{t("home_welcome", { name: customerName })}</Greeting>
          <SubGreeting>{t("home_select")}</SubGreeting>
          {cart && store ? <BoxChangeDisposition /> : <Disposition />}
          <PaymentImage>
            <Image src="images/payment_offered.png" width="556px" height="100px"></Image>
          </PaymentImage>
        </Container>
      </ContainerBg>
      <HotPromoArea />
    </React.Fragment>
  );
}

export default HomeInit(Home);
