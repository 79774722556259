import { contentAPI } from "constants";
import { fetchData } from "../fetch";

export const serviceGetBannerHomeTop = (t) => {
  const url = `${contentAPI}/banners/home-top`;
  return fetchData({
    method: "get",
    url,
  });
};

export const serviceGetBannerHomeBottom = () => {
  const url = `${contentAPI}/banners/home-bottom`;
  return fetchData({
    method: "get",
    url,
  });
};
