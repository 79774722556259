import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { actionClickSaveStoreInfo } from "redux/actions/store";
import { actionSaveOrderType, actionSaveCartInfo, actionUpdateOrderSchedule } from "redux/actions/order";

import { getAvailableStoreToTakeaway, getAvailableStoreToDelivery } from "services/store";
import { getLatestCart } from "services/order/cart";

import { showAppLoading } from "utils";
import { common } from "constants";
import { actionSavePrevAddrSelected } from "redux/actions/customer";

export default function HomeInit(Component) {
  return (props) => {
    const dispatch = useDispatch();

    const token = useSelector(({ customerReducer }) => customerReducer.token);
    const store = useSelector(({ storeReducer }) => storeReducer.store);

    useEffect(async () => {
      if(store) return;
      showAppLoading();
      const latestCart = await getLatestCart(token);
      const { information = {} } = latestCart;
      const { cart_uuid, disposition, store_uuid, address_mapping_uuid, delivery, collection_info } = information;
      if(!cart_uuid) return showAppLoading(false);

      let storeDetail;
      if (disposition == common.ORDER_TYPE.DELIVERY) {
        storeDetail = await getAvailableStoreToDelivery(address_mapping_uuid);
      } else {
        storeDetail = await getAvailableStoreToTakeaway(store_uuid);
      }
      showAppLoading(false);

      dispatch(actionSaveOrderType(disposition));
      dispatch(actionClickSaveStoreInfo(storeDetail));
      dispatch(actionSaveCartInfo(latestCart));
      dispatch(actionUpdateOrderSchedule(collection_info));
      dispatch(actionUpdateOrderSchedule(collection_info));
      dispatch(actionSavePrevAddrSelected(delivery))
    }, []);

    return <Component {...props} />;
  };
}
