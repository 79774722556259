import React from "react";
import { useSelector } from "react-redux";


export default function WithDeliveryAddress(Component) {
  return (props) => {
    const store = useSelector(({ storeReducer }) => storeReducer.store);
    const cart = useSelector(({ orderReducer }) => orderReducer.cart);
    const prevOrderAddrSelected = useSelector(
        ({ customerReducer }) => customerReducer.prevOrderAddrSelected,
    );

    let customerAddress = "";
    if (cart) {
      customerAddress = cart.information.full_address_customer || "";
    }

    let storeAddress = "";
    if (store) {
      storeAddress = store.full_address_landing_page || "";
    }

    const deliveryTo =
        customerAddress ||
        (prevOrderAddrSelected || {}).full_address_customer ||
        storeAddress;


    return (
      <Component
        {...props}
        deliveryAddress={deliveryTo}
      />
    );
  };
}
