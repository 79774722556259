import React from "react";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import NotificationDialog from "../../Utils/NotificationDialog";

import { common } from "constants";

import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useRouter } from "next/router";
import { actionClearStore } from "redux/actions/store";
import { actionClickDeleteCart } from "redux/actions/order";
import Paper from "@mui/material/Paper";

import WithDeliveryAddress from "hoc/WithDeliveryAddress";

import { useTranslation } from "react-i18next";

const BoxChangeDisposition = ({ deliveryAddress }) => {
  const { t } = useTranslation();
  const router = useRouter();
  const dispatch = useDispatch();

  const [showNotify, setShowNotify] = useState(false);
  const store = useSelector(({ storeReducer }) => storeReducer.store);
  const orderType = useSelector(({ orderReducer }) => orderReducer.orderType);

  const dialogTitle =
    orderType == common.ORDER_TYPE.DELIVERY
      ? t("delivery_to", { to: deliveryAddress })
      : t("collection_from", { from: store.name });

  return (
    <>
      <NotificationDialog
        open={showNotify}
        description={t("confirm_change_disposition")}
        onClose={() => setShowNotify(false)}
        onDone={() => {
          setShowNotify(false);
          dispatch(actionClearStore());
          dispatch(actionClickDeleteCart());
        }}
        txtDone="common_change"
      />
      <Paper sx={{ padding: "16px", marginTop: "24px" }}>
        <Typography
          variant="subtitle2"
          fontWeight="bold"
          sx={{ marginBottom: "16px" }}
        >
          {dialogTitle}
        </Typography>
        <Button
          fullWidth
          variant="grey"
          sx={{ marginBottom: "16px" }}
          onClick={() => setShowNotify(true)}
        >
          {t("common_change")}
        </Button>
        <Button
          fullWidth
          variant="contained"
          onClick={() => router.push("/menu")}
        >
          {t("view_menu")}
        </Button>
      </Paper>
    </>
  );
}

export default WithDeliveryAddress(BoxChangeDisposition);
