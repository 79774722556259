import React, { useEffect, useState } from "react";
import { serviceGetBannerHomeTop } from "../../../services/content";

export default function TopBanner() {
  const [imgSrc, setImgSrc] = useState("");

  useEffect(async () => {
    const data = await serviceGetBannerHomeTop();
    setImgSrc(data && data[0] ? data[0].small_image : "");
  }, []);

  return <>{imgSrc ? <img src={imgSrc} width="100%" /> : null}</>;
}
