import ListItemButton from "@mui/material/ListItemButton";
import { common } from "../../../constants";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Image from "next/image";
import ListItemText from "@mui/material/ListItemText";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import List from "@mui/material/List";
import React, {useEffect, useState} from "react";

import { useRouter } from "next/router";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import styled from "styled-components";

import { actionSaveOrderType } from "redux/actions/order";

import SchedulePopup from "../SchedulePopup";
import WithSavedInfo from "hoc/WithSavedInfo";

const SavedInfo = styled.div`
  margin-top: 5px;
  padding-left: 56px;
  width: 100%;
`;

const Disposition = ({ prevOrderInfo, onSelectSavedInfo, openScheduleDialog = false }) => {
  const { t } = useTranslation();
  const router = useRouter();
  const dispatch = useDispatch();

  const [selectedSavedInfo, setSelectedSavedInfo] = useState("");
  const [openTime, setOpenTime] = useState(false);

  const handleSelectOrderType = (id) => {
    dispatch(actionSaveOrderType(id));
    if (id == common.ORDER_TYPE.DELIVERY) {
      router.push("/delivery");
      return;
    }
    router.push("/takeaway");
  };

  const handleSelectSavedInfo = (e, orderType) => {
    setSelectedSavedInfo(e.target.value);
    onSelectSavedInfo(e, null, orderType);
  };

  useEffect(()=> {
    setOpenTime(openScheduleDialog);
  }, [openScheduleDialog]);

  return (
    <>
      {openTime ? (
        <SchedulePopup
          open={openTime}
          onDone={() => {
            setOpenTime(false);
            router.push("/menu");
          }}
          handleClose={() => setOpenTime(false)}
        />
      ) : null}
      <List sx={{ width: "100%" }}>
        <ListItemButton
          onClick={() => handleSelectOrderType(common.ORDER_TYPE.DELIVERY)}
          sx={{
            marginBottom: "16px",
            bgcolor: "background.paper",
          }}
          disableTouchRipple
        >
          <ListItem disableGutters sx={{ flexFlow: "row wrap" }}>
            <ListItemIcon>
              <Image
                src="icons/delivery.svg"
                width="24px"
                height="24px"
              ></Image>
            </ListItemIcon>
            <ListItemText
              primary={t("home_delivery")}
              secondary={t("home_delivery_description")}
            />
            <ChevronRightIcon />
            {prevOrderInfo.delivery_addresses.length ? (
              <SavedInfo onClick={(e) => e.stopPropagation()}>
                <FormControl fullWidth size="small">
                  <InputLabel>{t('select_saved_address')}</InputLabel>
                  <Select
                    value={selectedSavedInfo}
                    fullWidth
                    label={t('select_saved_address')}
                    onChange={(e) =>
                      handleSelectSavedInfo(e, common.ORDER_TYPE.DELIVERY)
                    }
                  >
                    {prevOrderInfo.delivery_addresses.map((item) => (
                      <MenuItem key={item.uuid} value={item.uuid}>
                        {item.full_address_customer}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </SavedInfo>
            ) : null}
          </ListItem>
        </ListItemButton>
        <ListItemButton
          onClick={() => handleSelectOrderType(common.ORDER_TYPE.TAKEAWAY)}
          sx={{
            bgcolor: "background.paper",
          }}
          disableTouchRipple
        >
          <ListItem disableGutters sx={{ flexFlow: "row wrap" }}>
            <ListItemIcon>
              <Image
                src="icons/takeaway.svg"
                width="24px"
                height="24px"
              ></Image>
            </ListItemIcon>
            <ListItemText
              primary={t("home_takeaway")}
              secondary={t("home_takeaway_description")}
            />
            <ChevronRightIcon />
            {prevOrderInfo.collection_stores.length ? (
              <SavedInfo onClick={(e) => e.stopPropagation()}>
                <FormControl fullWidth size="small">
                  <InputLabel>{t('select_past_takeaway')}</InputLabel>
                  <Select
                    value={selectedSavedInfo}
                    fullWidth
                    label={t('select_past_takeaway')}
                    onChange={(e) =>
                      handleSelectSavedInfo(e, common.ORDER_TYPE.TAKEAWAY)
                    }
                  >
                    {prevOrderInfo.collection_stores.map((item) => (
                      <MenuItem key={item.uuid} value={item.store_uuid}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </SavedInfo>
            ) : null}
          </ListItem>
        </ListItemButton>
      </List>
    </>
  );
};

export default WithSavedInfo(Disposition);
